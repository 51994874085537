.SummaryReportModal {
  width: 80% !important;
  max-width: 1000px !important;
}

.SummaryReportModalHeader {
  margin-right: 8px;
}

.SummaryReportModalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media @mobile {
    gap: 20px;
  }
}

.SummaryReportModalRowGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media @mobile {
    gap: 12px;
  }
}

.SummaryReportModalRowContainer {
  display: inline-grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);

  @media (@mobile) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.SummaryReportModalRowItem {
  .Text2();

  @media @mobile {
    .TableHeader();
  }
}

.SummaryReportModalRowItemHeader {
  .Text2Bold();

  @media (@mobile) {
    .TableHeader();
  }
}

.SummaryReportModalRowItemSubHeader {
  .TableHeader();
  color: @text-secondary;

  @media (@mobile) {
    .Caption();
    color: @text-secondary;
  }
}

.SummaryReportModalDivider {
  grid-column-start: 2;
  grid-column-end: 7;
  height: 1px;
  background-color: @separator-primary;

  @media (@mobile) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}