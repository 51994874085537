.TableInside {
  display: flex;
  flex-direction: column;

  .TableInsideRow {
    display: inline-grid;
    padding: 16px 16px 16px 32px;
    width: 100%;
    .Text1();

    &:hover {
      background-color: @background-tertiary;
    }
  }

  .TableInsideContrastRow {
    background-color: @background-tertiary;
  }

  .TableInsideItem {
    display: flex;
    align-items: center;
  }

  .TableInsideContrastRow .TableInsideItem:first-child {
    gap: 12px;
  }
}

.TableInside > div {
  display: flex;
  flex-direction: column;
}

.SummaryReportTableInside {
  .TableInsideItem:first-child {
    padding-left: 0;
  }
  .TableInsideItem:nth-child(2) {
    padding-left: 23%;
  }
  .TableInsideItem:nth-child(3) {
    padding-left: 23%;
  }
  .TableInsideItem:nth-child(4) {
    padding-left: 23%;
  }
  .TableInsideItem:nth-child(5) {
    padding-left: 24%;
  }
  .TableInsideItem:nth-child(6) {
    padding-left: 24%;
  }
  .TableInsideItem:nth-child(7) {
    padding-left: 23%;
  }

  .TableInsideRow {
    grid-template-columns: 16% 14% 14% 14% 14% 14% 14%;
  }
}
