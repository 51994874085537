// color palette
@text-primary: #F1F3FB;
@text-secondary: #A9C5F0;
@text-tertiary: #FAE91C;
@text-complementary: #4088DE;
@text-auxiliary: #216CC5;
@text-analogous: #181E24;

@background-primary: #091D35;
@background-secondary: #0D2E55;
@background-tertiary: #143F74;

@button-primary: #F6E307;

@hover-primary: #2879DA;
@hover-secondary: #143F74;
@hover-tertiary: #15447D;
@hover-button: #FBEB39;

@icon-primary: #1D5EAD;
@icon-secondary: #F6E307;
@icon-tertiary: #A9C5F0;
@icon-complementary: #EEF1F4;

@auxiliary-primary: #DFCD00;
@auxiliary-white: #FFFFFF;

@separator-primary: #1D5EAD;
@separator-secondary: #195195;
@separator-tertiary: #15447D;

@red-420: #DA4343;
@orange-420: #FBA11E;
@turquoise-780: #145F51;
@turquoise-420: #44D8BD;
@grey-420: #788EA5;
@grey-540: #5B7289;
@grey-600: #506478;

@modal-background: rgba(13, 15, 16, 0.5);
@light-shadow: rgba(0, 0, 0, 0.12);
@dark-shadow: rgba(0, 0, 0, 0.16);
@label-color-dark-secondary: rgba(235, 235, 245, 0.60);


// media
@mobile: ~'(min-width: 320px) and (max-width: 1129px)';
@desktopSmall: ~'(min-width: 1130px) and (max-width: 1336px)';
@desktopMedium: ~'(min-width: 1337px) and (max-width: 1440px)';
@desktopLarge: ~'(min-width: 1441px) and (max-width: 1920px)';
@desktopXLarge: ~'(min-width: 1921px)';

// fonts
@font-face {
  font-family: 'Gilroy-500';
  font-weight: 500;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-600';
  font-weight: 600;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Semibold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-700';
  font-weight: 700;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-900';
  font-weight: 900;
  src: local('Gilroy'), url(./fonts/Gilroy/Gilroy-Extrabold.otf) format('truetype');
}

@font-face {
  font-family: 'Lato-400';
  font-weight: 400;
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-500';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-600';
  font-weight: 600;
  src: local('Lato'), url(./fonts/Lato/Lato-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-700';
  font-weight: 700;
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}

// typography
.Headline1 {
  font-family: 'Gilroy-700', sans-serif;
  font-weight: 700;
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  color: @text-primary;
}

.Headline2 {
  font-family: 'Gilroy-700', sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.4px;
  color: @text-primary;
}

.Headline3 {
  font-family: 'Gilroy-700', sans-serif;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: @text-primary;
}

.Subtitle1 {
  font-family: 'Gilroy-600', sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  color: @text-primary;
}

.Subtitle2 {
  font-family: 'Lato-400', sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: @text-primary;
}

.Body1 {
  font-family: 'Lato-700', sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: @text-primary;
}

.Body2 {
  font-family: 'Lato-400', sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: @text-primary;
}

.Text1 {
  font-family: 'Lato-400', sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: @text-primary;
}

.Text2 {
  font-family: 'Lato-400', sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  color: @text-primary;
}

.TextBold {
  font-family: 'Lato-700', sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: @text-primary;
}

.Text2Bold {
  font-family: 'Lato-700', sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  color: @text-primary;
}

.TableHeader {
  font-family: 'Gilroy-500', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  color: @text-primary;
}

.Caption {
  font-family: 'Lato-400', sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: @text-primary;
}

.CaptionBold {
  font-family: 'Lato-600', sans-serif;
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: @text-primary;
}

.Overline {
  font-family: 'Lato-400', sans-serif;
  font-weight: 400;
  font-size: 11px;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: @text-primary;
}

.Overline1 {
  font-family: 'Lato-500', sans-serif;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: @text-primary;
}

.Button {
  font-family: 'Lato-600', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.2px;
  text-align: center;
  color: @text-primary;
}

.Button1 {
  font-family: 'Gilroy-600', sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.2px;

  @media @mobile {
    font-size: 14px;
    line-height: 18px;
  }
}

.Button2 {
  font-family: 'Gilroy-600', sans-serif;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.2px;
}


//scroll
.VerticalScrollGutter {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 24px;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border: 10px solid transparent;
    max-height: 56px;
    min-height: 56px;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: @separator-primary;
  }
}

.VerticalScroll {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    max-height: 56px;
    border-radius: 4px;
    background-color: @separator-primary;
  }
}

.HorizontalScroll {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-clip: content-box;
    border: 8px solid transparent;
    border-radius: 4px;
    background-color: @separator-primary;
  }
}

.VerticalAndHorizontalScroll {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    max-height: 56px;
    border-radius: 4px;
    background-color: @separator-primary;
  }

  &::-webkit-scrollbar-track:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    background-color: @separator-primary;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.VerticalScrollInvisible {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.TableHorizontalScroll {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 0;
    background-color: @background-secondary;
  }

  &::-webkit-scrollbar-track:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 20px;
    background-color: @separator-tertiary;
  }
}

// icon sizes
.IconL {
  width: 24px;
  height: 24px;
}

.IconM {
  width: 20px;
  height: 20px;
}

.IconRotate180 {
  rotate: (-180deg);
  transition: rotate 0.3s;
}

.SuspenseLoader {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

@media @mobile {
  .ant-picker-dropdown {
    top: 0 !important;
    left: 32px !important;
  }
}

// shadow

.Shadow {
  box-shadow: 0 0 2px 0 @light-shadow, 0 0 96px 0 @dark-shadow;
}

.Shadow1 {
  box-shadow: 0 4px 4px 0 rgba(9, 29, 53, 0.25), 0 4px 4px 0 rgba(9, 29, 53, 0.04);
}